import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: BaseArticleTeaserDefaultTheme.Image,
  slots: {
    base: ['rounded-2xl'],
  },
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    base: ['group-[&:not(.bg)]/box:bg-white', 'rounded-2xl', 'overflow-hidden'],
    caption: ['text-tag-sm', 'text-red', 'group-[.bg-red]/box:text-yellow', 'group-[.bg-blue]/box:text-yellow'],
    description: [
      'text-kicker-sm',
      '!mt-0', // override container-col
      'text-gray-950',
      'group-[.bg-red]/box:text-white',
      'group-[.bg-blue]/box:text-white',
    ],
    headline: ['text-gray-950', 'group-[.bg-red]/box:text-white', 'group-[.bg-blue]/box:text-white'],
  },
  variants: {
    variant: {
      vertical: {
        base: [
          'relative',
          'gap-0',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:grid-cols-[53%_auto]',
          'group-[&[data-title-position=titleRightSideOfImage]]/box:grid-cols-[auto_53%]',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:items-center',
          'group-[&[data-title-position=titleRightSideOfImage]]/box:items-center',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:[&>a]:order-2',
        ],
        footer: ['col-span-full'],
        group: ['gap-y-1', 'px-3', 'pt-3', 'pb-4'],
        header: ['col-span-full', 'flex', 'max-w-full', 'flex-row', 'items-center', 'uppercase'],
        headline: [
          'text-headline-xl',
          'container-col-sm-7:text-headline-xl',
          'container-col-sm-8:text-headline-xl',

          'sm:text-headline-2xl',
          'sm:container-col-lg-7:text-headline-2xl',
          'sm:container-col-lg-10:text-headline-2xl',

          'md:text-headline-3xl',
          'md:container-col-lg-7:text-headline-3xl',
          'md:container-col-lg-10:text-headline-3xl',
        ],
        caption: ['mb-0.5', 'sm:mb-1.5'],
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
