import { tw } from '@/utils/tw';
import BaseAdunitTheme from 'base/components/Adunit/theme';

const Adunit = tw.theme({
  extend: BaseAdunitTheme,
  slots: {
    base: [
      'relative',
      'z-10',
      'my-0',
      'w-full',
      'max-w-content',
      'mx-auto',
      'text-center',
      'wings',
      'bg-gray-100',
      'wings-gray-100',
      'rounded',
    ],
    headline: ['text-input-xs'],
  },
});

export default Adunit;
